import { RouteComponentProps } from '@reach/router'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { Generic, GenericProps } from 'common/components/templates/Generic'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface TemplateGenericPageProps extends RouteComponentProps {
    pageContext: any
}

export const TemplateGenericPage: React.FC<TemplateGenericPageProps> = ({
    pageContext,
}: any) => {
    console.log(pageContext)

    return <Generic {...getGenericData(pageContext)} />
}

export default TemplateGenericPage

export const getGenericData = (data: any): GenericProps => {
    return {
        codename: data.codename,
        layout: getLayoutData(data),
        jumbotron: getJumbotronData(data?.hero?.[0]),
        sections: data.sections,
    }
}
